import * as Cookies from 'es-cookie';

function get(name) {
	return Cookies.get(name);
}

function set(name, value, options = {}) {
	Cookies.set(name, value, options);
}

const cookieService = {
	get,
	set
};

export default cookieService;
