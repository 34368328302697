/* eslint-disable class-methods-use-this */
/* eslint-disable dot-notation */
// eslint-disable-next-line import/no-unresolved
import cssString from 'bundle-text:./style.scss';
import cookieService from './service/cookieService';
import { bannerText, buttonText } from '../public/static/locales/common.json';
import config from '../config';

export class PdCookieBanner extends HTMLElement {
	constructor() {
		super();

		this.name = 'pd-cookie-banner';
		this.shadow = this.attachShadow({ mode: 'open' });

		const attrs = this.attributes;
		const secureOption = attrs['secure']?.value ?? config.secureOption;

		this.privacyPolicyVersion = attrs['privacy-policy-version']?.value ?? config.privacyPolicyVersion;
		this.cookieName = attrs['cookie-name']?.value ?? config.cookieName;

		const siteDomain =
			window.location.host.indexOf(config.cookieDomain) > -1 ? config.cookieDomain : config.cookieGlobalDomain;

		this.options = {
			path: attrs['path']?.value ?? config.cookiePath,
			domain: attrs['domain']?.value ?? siteDomain,
			secure: secureOption === 'true',
			expires: this.getExpireDate(config.cookieExpireDate)
		};

		const language = this.getLanguageByDomain(window.location.hostname);

		this.template = /* html */ `
            <style>
                ${cssString}
            </style>
            <div class="pd_cookie_banner">
                <p class="pd_cookie_banner__text">${bannerText[language]}</p>
                <button type="button" class="pd_cookie_banner__button pd-button-primary">${buttonText[language]}</button>
            </div>
        `;
	}

	getLanguageByDomain(domain) {
		const domainName = domain.split('.')[1];

		if (domainName === 'studenta') {
			return 'es';
		}
		return 'pt-BR';
	}

	connectedCallback() {
		this.shadow.innerHTML = '';

		if (!this.cookieIsSet()) {
			this.shadow.innerHTML = this.template;

			this.shadow.querySelector('.pd_cookie_banner__button').addEventListener('click', () => {
				cookieService.set(this.cookieName, this.privacyPolicyVersion, this.options);
				this.shadow.innerHTML = '';
			});
		}

		this.setPositionClass();
		this.setBannerTypography();
	}

	setPositionClass() {
		const bannerPosition = this.getPosition(this.attributes['position']?.value);
		this.shadow.querySelector('.pd_cookie_banner')?.classList.add(bannerPosition);
	}

	setBannerTypography() {
		const bannerTypography = this.getTypography(this.attributes['typography']?.value);
		this.shadow.querySelector('.pd_cookie_banner__text')?.classList.add(bannerTypography);
	}

	cookieIsSet() {
		const privacyCookieValue = cookieService.get(this.cookieName);
		return privacyCookieValue === this.privacyPolicyVersion;
	}

	getPosition(position) {
		return position === 'top' ? 'pd_cookie_banner__top' : 'pd_cookie_banner__bottom';
	}

	getTypography(typography) {
		return typography || 'pd-heading-sm';
	}

	getExpireDate(dateOption) {
		// Cookies must have a expire value to last longer than a sesion,
		// to avoid problems in older browsers the max value must be less than 2^32
		// for more info: https://stackoverflow.com/a/22479460
		const max32BitDateValue = (2 ** 31 - 1) * 1000;
		const maxDate = new Date(max32BitDateValue);
		const selectedDate = new Date(dateOption);

		const selectedIsBiggerThanLimit = selectedDate.getTime() > maxDate.getTime();

		return selectedIsBiggerThanLimit ? maxDate : selectedDate;
	}
}

if (typeof window !== 'undefined') {
	customElements.define('pd-cookie-banner', PdCookieBanner);
}
