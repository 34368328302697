{
  "bannerText": {
    "pt-BR": "Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a nossa <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://sobreuol.noticias.uol.com.br/normas-de-seguranca-e-privacidade\">Política de Privacidade</a> e, ao continuar você concorda com essas condições.",
    "es": "Usamos cookies esenciales y tecnologías similares de acuerdo con nuestra <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://sobreuol.noticias.uol.com.br/normas-de-seguranca-e-privacidade/es\">Política de Privacidad</a>. Al continuar, aceptas estas condiciones."
  },
  "buttonText": {
    "pt-BR": "Ok",
    "es": "Ok"
  }
}
